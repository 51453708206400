@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@100;200;300;400;500;600;700&display=swap');

html, body {
  margin: 0;
  font-family: Inter, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
body {
  background-color: #eeeeee;
  overflow-y: scroll;
}

hr {
  color: #CBD2D9
}

.vh-100 {
  height: 100vh;
}

.text-tajir {
  color: #4caf50;
}

.bg-tajir {
  background-color: #4caf50;
}

.border-tajir-y {
  border-top: 8px solid #4caf50;
  border-bottom: 8px solid #4caf50;
}

.border-tajir-top {
  border-top: 8px solid #4caf50;
}

.border-tajir-x {
  border-left: 8px solid #4caf50;
  border-right: 8px solid #4caf50;
}

.urdu-container {
  background-color: #EBF8FF;
  color: #2680C2;
  border-radius: 0.5rem;
  font-family: "Noto Sans Arabic", serif;
  font-weight: 500;
  line-height: 175%;
  text-align: right;
}
.urdu-container-green {
  background-color: #E3F9E5;
  color: #3b723d;
}

.tajir-logo {
  width: 512px;
  position: absolute;
  left: 50%;
  margin-left: -260px;
  top: 50%;
  opacity: 0.274;
}

.btn-success {
  --bs-btn-bg: #4caf50;
  --bs-btn-border-color: #4caf50;
  --bs-btn-hover-bg: #198754;
}

.label {
  font-size: 14px;
  font-weight: 800;
  text-transform: uppercase;
  color: #4caf50;
  margin-bottom: 8px;
  letter-spacing: 0.05rem;

}
a {
  color: #2680c2;
}
.link:hover {
  color: #2680c2;
}

@media print {
  .table-row {
    page-break-inside: avoid;
  }
  .no-print {
    display: none!important;
  }
  .no-print-shadow {
    box-shadow: none!important;
  }
  .no-print-margin {
    margin: 0!important;
  }
  .no-print-padding {
      padding: 0!important;
  }
  .no-print-border {
    border: none!important;
  }
  .no-print-break {
    width: 50%!important;
  }

}

.footer {
  text-decoration: none;
  color: grey;
}
.footer:hover {
  color: darkslategray!important;
}

/*Transition Classes*/
.fade-enter {
  opacity: 0;
  transform: scale(0.9);
}
.fade-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms, transform 500ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 500ms, transform 500ms;
}

/* Po Contents */
#po-container {
  width: 850px;
  justify-self: center;
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}
.table-row:hover {
  background-color: #F5F7FA;
}
.sku-name {
  font-size: min(calc(13px + (16 - 13) * ((100vw - 360px) / (800 - 300))),16px);
}
.header-line{
  height: 2px;
  background: #4caf50;
  opacity: 1;
  color: #4caf50;
}
.footer-line{
  height: 2px;
  opacity: 1;
  background: lightgray;
  color: lightgrey;
}
.pill {
  max-width: 300px;
}
.serial-num {
  min-width: 30px;
  font-size: 14px;
  text-align: left;
}
.quantity-label {
  font-size: min(calc(8px + (12 - 8) * ((100vw - 360px) / (800 - 300))),12px);
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.05rem;
}
.quantity {
  font-size: min(calc(13px + (16 - 13) * ((100vw - 360px) / (800 - 300))),16px);
  font-weight: 800;
  text-align: center;
}
.equal {
  display: flex;
  align-items: center;
}
@media (max-width: 991px) {
  .quantity-label{
      letter-spacing: normal;
  }
}
@media (max-width: 767px) {
  #po-container {
    margin: 0px!important;
    border-radius: 0px!important;
    padding-top: 0.5rem!important;
    padding-left: 0.5rem!important;
    padding-right: 0.5rem!important;
  }
  .equal {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .serial-num {
    font-size: 12px;
  }
  .quantity-label{
    letter-spacing: 0.05rem;
    text-align: center;
    padding: 0!important;
  }
  .quantity {
      text-align: center;
  }
  .pill {
    line-height: 1.25;
  }
}
@media (max-width: 575px) {
  #po-container {
    padding-top: 0.25rem!important;
    padding-left: 0.25rem!important;
    padding-right: 0.25rem!important;
  }
  .table-row {
    margin-top: 0.25rem!important;
    margin-bottom: 0.25rem!important;
  }
}

/*Responsive Fonts*/
.responsive-font-1 {
  font-size: min(calc(8px + (12 - 8) * ((100vw - 360px) / (800 - 300))),12px);
}
.responsive-font-2 {
  font-size: min(calc(10px + (14 - 10) * ((100vw - 360px) / (800 - 300))),14px);
}
.responsive-font-3 {
  font-size: min(calc(13px + (16 - 13) * ((100vw - 360px) / (800 - 300))),16px);
}

/* Bootstrap custom classes for helping responsiveness */

.mt-responsive-3 {
  margin-top: 1rem!important;
}
.mt-responsive-4 {
  margin-top: 1.5rem!important;
}
@media (max-width: 575px) {
  .mt-responsive-3 {
    margin-top: 0.5rem!important;
  }
  .mt-responsive-4 {
    margin-top: 1rem!important;
  }
}
